export   const subjects = [
    { "id": "Français", "content": "Français" },
    { "id": "Mathématiques", "content": "Mathématiques" },
    { "id": "Sciences Physiques", "content": "Sciences Physiques" },
    { "id": "Sciences de la Vie et de la Terre", "content": "Sciences de la Vie et de la Terre" },
    { "id": "Histoire et Géographie", "content": "Histoire et Géographie" },
    { "id": "Philosophie", "content": "Philosophie" },
    { "id": "Anglais", "content": "Anglais" },
    { "id": "Espagnol", "content": "Espagnol" },
    { "id": "Allemand", "content": "Allemand" },
    { "id": "Informatique", "content": "Informatique" },
    { "id": "Éducation Physique et Sportive", "content": "Éducation Physique et Sportive" },
    { "id": "Sciences Économiques", "content": "Sciences Économiques" },
    { "id": "Sciences Sociales", "content": "Sciences Sociales" },
    { "id": "Éducation Civique", "content": "Éducation Civique" },
    { "id": "Musique", "content": "Musique" },
    { "id": "Arts Plastiques", "content": "Arts Plastiques" },
    { "id": "Gestion", "content": "Gestion" },
    { "id": "Comptabilité", "content": "Comptabilité" },
    { "id": "Philosophie", "content": "Philosophie" },
    { "id": "Latin", "content": "Latin" },
    { "id": "Grec", "content": "Grec" },
    { "id": "Arabe", "content": "Arabe" },
    { "id": "Droit", "content": "Droit" },
    { "id": "Communication", "content": "Communication" },
    { "id": "Technologie", "content": "Technologie" },
    { "id": "Éducation Artistique", "content": "Éducation Artistique" },
    { "id": "Sciences Politiques", "content": "Sciences Politiques" },
    { "id": "Économie", "content": "Économie" },
    { "id": "Management", "content": "Management" },
    { "id": "Marketing", "content": "Marketing" },
    { "id": "Finance", "content": "Finance" },
    { "id": "Ressources Humaines", "content": "Ressources Humaines" },
    { "id": "Logistique", "content": "Logistique" },
    { "id": "Entrepreneuriat", "content": "Entrepreneuriat" },
    { "id": "Commerce International", "content": "Commerce International" },
    { "id": "Gestion de Projet", "content": "Gestion de Projet" }
]