import React from "react";
import "../../../assets/css/addbutton.css";

const AddButton = ({ text, onOpen }) => {
  return (
    <button className="add__button" onClick={() => onOpen(true)}>
      <div className="text">{text}</div>
      <span className="icon">+</span>
    </button>
  );
};

export default AddButton;
