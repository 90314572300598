//import { useNavigate } from "react-router-dom";
import { upload } from "./upload";
import { accountService } from "../service/account.service";
import { isError } from "@tanstack/react-query";

//import { accountService } from "../service/account.service";
//import { useDispatch } from "react-redux";
//import { setToken } from "./redux/auth.slice";
const endpoint = "login";


async function login(body, navigate, setCodeErreur, setIsErreur) {
    //const dispath=useDispatch;


    const reponse = await upload(endpoint, body);



    //const data=await reponse.json()
    if (!reponse.ok) {
        // return data.message 
        //console.log("resultat inscription ", reponse.status)
        setCodeErreur(reponse.status)
        setIsErreur(true)


        await reponse.json().then(data => {
            // navigate('/home',{ state:  data.user_id});//naivguer si l'inscription est ok
            // console.log("le message d'erreur ", data)
        });
    }
    else {
        await reponse.json().then(data => {
            // navigate('/home',{ state:  data.user_id});//naivguer si l'inscription est ok
            // console.log("le message d'erreur ",data)
            verifUser(data, navigate)
        });
    }
    //console.log("reponse1 ",reponse)
    // console.log("reponse de la connexion connexion",data)
}
export { login }

function verifUser(data, navigate) {
    // console.log("l'tulisatuer connecter ",data)
    const id = data.user.role.id;
    if (id === 1) {
        // console.log("user", data.user.ecole.id)

        const token = data.token;
        const ecole = data.user.ecole.id
        const id_ecole = data.user.ecole.id
        accountService.saveToken(token)
        accountService.saveEcole(id_ecole)

        accountService.saveId(id)
        navigate('/home', { state: data.token })
        // return data

    }
}

