import React, { useContext, useState } from "react";
import {
  IoBookmarkOutline,
  IoPersonAddOutline,
  IoStarOutline,
} from "react-icons/io5";
import DropdownList from "../../components/form/DropdownList";
import PopupForm from "../../components/ui/modal/PopupForm";
import { ClassContext } from "../../contexts/ClassContext";
import { GetProfs } from "../../API/react_query/react_query";
import { addSubjet } from "../../API/fonctions/addSubjet";
import { subjects } from "../../utils/list";

const AddSubjectPopup = ({ onClose }) => {
  const [idProf, setIdProf] = useState(null);
  const [idMatiere, setIdMatiere] = useState(null);
  const [idCoefficient, setIdCoefficient] = useState(null);
  const { currentClass, anneeScol } = useContext(ClassContext);
  const { data: professeur, isLoading: isLoadingProf } = GetProfs(anneeScol);

  console.log("professeur", professeur)


  const teachers = professeur?.data?.map((prof) => ({
    id: prof.prof.id,
    content: prof.prof.nom_complet,
  }));

  const coefficients = [
    { id: 1, content: "Coef : 1" },
    { id: 2, content: "Coef : 2" },
    { id: 3, content: "Coef : 3" },
    { id: 4, content: "Coef : 4" },
    { id: 5, content: "Coef : 5" },
    { id: 6, content: "Coef : 6" },
    { id: 7, content: "Coef : 7" },
    { id: 8, content: "Coef : 8" },
    { id: 9, content: "Coef : 9" },
    { id: 10,content: "Coef : 10" },
    
  ];

  const handleTeacherSelect = (selectedId) => {
    setIdProf(selectedId);
    console.log("Selected teacher ID:", selectedId);
  };

  const handleSubjectSelect = (selectedId) => {
    setIdMatiere(selectedId);
    console.log("Selected subject ID:", selectedId);
  };

  const handleCoefficientSelect = (selectedId) => {
    setIdCoefficient(selectedId);
    console.log("Selected coefficient ID:", selectedId);
  };

  const handleSubmit = () => {
    // Add your logic here to handle form submission
   // console.log("Form submitted with:", { idProf, idMatiere, idCoefficient });
   const body = {
    "libelle": idMatiere,
    "description": "la vie est belle",
    "coef": idCoefficient,
    "classe_id":currentClass,
    "professeur_id": idProf,
    "annee_id":anneeScol
   }
   addSubjet(body, onClose);
  };

  return (
    <PopupForm
      onClose={onClose}
      title="Ajouter une matière"
      onSubmit={handleSubmit}
    >
      <DropdownList
        icon={<IoBookmarkOutline />}
        items={subjects}
        setValue={handleSubjectSelect}
      />

      <DropdownList
        icon={<IoStarOutline />}
        items={coefficients}
        setValue={handleCoefficientSelect}
      />

      <DropdownList
      icon={<IoPersonAddOutline />}
      items={teachers}
      setValue={handleTeacherSelect}
      isLoading={isLoadingProf}
    />
    </PopupForm>
  );
};

export default AddSubjectPopup;
