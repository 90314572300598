import React, { useContext, View, Text } from "react";
import { Header, Navbar } from "../../layouts";
import "../../assets/css/home.css";
import {
  IoArrowBack,
  IoArrowForward,
  IoBag,
  IoCash,
  IoPeople,
  IoPerson,
  IoPersonAdd,
  IoTerminal,
} from "react-icons/io5";
import DashboardAmountBoxLarge from "./DashboardAmountBoxLarge";
import DashboardAmountBoxMedium from "./DashboardAmountBoxMedium";
import DashboardFinanceBox from "./DashboardFinanceBox";
import NotifCard from "./NotifCard";
import NumCard from "./NumCard";
import { accountService } from "../../API/service/account.service";
import {
  GetAllEleves,
  GetAllinscriptions,
  GetAllMensualite,
  GetAllprofs,
  GetAllsurveillants,
  GetClasseQuery,
  GetClasseQuery1,
  GetUserQuery,
} from "../../API/react_query/react_query";
import { ClassContext } from "../../contexts/ClassContext";
import Loading from "../../layouts/Loading";

const Home = () => {
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);

  const {
    isError: isErrorIns,
    isLoading: isLoadingIns,
    error: errorIns,
    refetch: refetchIns,
    isFetching: isFetchingIns,
    data: dataIns,
  } = GetAllinscriptions(anneeScol);
  const {
    isError: isErrorMens,
    isLoading: isLoadingMens,
    error: errorMens,
    refetch: refetchMens,
    isFetching: isFetchingMens,
    data: dataMens,
  } = GetAllMensualite(anneeScol);
  const {
    isError: isErrorElev,
    isLoading: isLoadingElev,
    error: errorElev,
    refetch: refetchElev,
    isFetching: isFetchingElev,
    data: dataElev,
  } = GetAllEleves(anneeScol);
  const {
    isError: isErrorProfs,
    isLoading: isLoadingProfs,
    error: errorProfs,
    refetch: refetchEProfs,
    isFetching: isFetchingProfs,
    data: dataProfs,
  } = GetAllprofs(anneeScol);
  const {
    isError: isErrorSurv,
    isLoading: isLoadingSurv,
    error: errorSurv,
    refetch: refetchESurv,
    isFetching: isFetchingSurv,
    data: dataSurv,
  } = GetAllsurveillants(anneeScol);

  //console.log("les inscriptions",dataSurv)

  const dataUser = GetUserQuery();
  const dataClasse = GetClasseQuery();

  if (!dataIns || !dataMens || !dataElev || !dataProfs || !dataSurv) {
    return (
      <>
        <Navbar />
        <Header title={<span className="logo">Caly</span>} />

        <div className="home-page">
          <Loading />
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Header title={<span className="logo">Caly</span>} />

      <div className="home-page">
        <div className="left-section">
          <section className="payments">
            <DashboardAmountBoxLarge
              icon={<IoBag />}
              title={"Inscriptions"}
              amount={dataIns.total}
              subtitle={"Francs CFA, pour cette année"}
              pourcents={0}
            />

            <DashboardAmountBoxLarge
              icon={<IoCash />}
              title={"Mensualités"}
              amount={dataMens.total}
              subtitle={"Francs CFA, pour ce mois "}
              pourcents={0}
            />
          </section>

          <h2>Utilisation des applications mobiles</h2>

          <section className="payments apps">
            <DashboardAmountBoxMedium
              icon={<IoPerson />}
              title={"Elèves"}
              amount={dataElev.total}
              pourcents={0}
            />

            <DashboardAmountBoxMedium
              icon={<IoPeople />}
              title={"Parents"}
              amount={"642"}
              pourcents={0}
            />

            <DashboardAmountBoxMedium
              icon={<IoPerson />}
              title={"Professeurs"}
              amount={dataProfs.total}
              pourcents={0}
            />
          </section>

          <h2>Comptabilité</h2>

          <section className="compta">
            <DashboardFinanceBox
              icon={<IoArrowForward />}
              amount={"1 455 000 FCFA"}
              subtitle={"encaissé durant ce mois"}
              scale={"+100k"}
            />

            <DashboardFinanceBox
              icon={<IoArrowBack />}
              amount={"255 000 FCFA"}
              subtitle={"dépensé durant ce mois"}
              scale={"-50.6k"}
            />
          </section>
        </div>

        <aside>
          <h2>Dernières notifications</h2>

          <article className="box notifications">
            <NotifCard
              title={"Devoir standardisés"}
              subtitle={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit"
              }
              detail={"Aujourdh'ui à 15:36"}
            />

            <NotifCard
              title={"Arret des cours"}
              subtitle={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit"
              }
              detail={"Aujourdh'ui à 15:36"}
            />

            <NotifCard
              title={"Reunion des surveillants"}
              subtitle={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit"
              }
              detail={"Aujourdh'ui à 15:36"}
            />
          </article>

          <h2>Effectifs</h2>

          <div className="effectifs">
            <NumCard
              icon={<IoPerson />}
              number={780}
              title={"élèves au total"}
            />

            <NumCard icon={<IoPeople />} number={360} title={"professeurs"} />

            <NumCard
              icon={<IoPersonAdd />}
              number={23}
              title={"administrateurs"}
            />
          </div>
        </aside>
      </div>
    </>
  );
};

export default Home;
