import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  IoAlarm,
  IoAlarmOutline,
  IoAnalytics,
  IoAnalyticsOutline,
  IoBagRemove,
  IoBagRemoveOutline,
  IoBookmark,
  IoBookmarkOutline,
  IoCalendar,
  IoCalendarOutline,
  IoDocumentText,
  IoDocumentTextOutline,
  IoFingerPrint,
  IoFingerPrintOutline,
  IoHome,
  IoHomeOutline,
  IoPeople,
  IoPeopleOutline,
} from "react-icons/io5";
import { ClassContext } from "../contexts/ClassContext";

const navList = [
  {
    activeIcon: <IoHome />,
    defaultIcon: <IoHomeOutline />,
    path: "/home",
    text: "Accueil",
  },
  {
    activeIcon: <IoPeople />,
    defaultIcon: <IoPeopleOutline />,
    path: "/eleves",
    text: "Elèves",
  },
  {
    activeIcon: <IoCalendar />,
    defaultIcon: <IoCalendarOutline />,
    path: "/emploi-du-temps",
    text: "Emploi du temps",
  },
  {
    activeIcon: <IoAlarm />,
    defaultIcon: <IoAlarmOutline />,
    path: "/absences",
    text: "Absences et retards",
  },
  {
    activeIcon: <IoDocumentText />,
    defaultIcon: <IoDocumentTextOutline />,
    path: "/notes",
    text: "Notes et bulletins",
  },
  {
    activeIcon: <IoBagRemove />,
    defaultIcon: <IoBagRemoveOutline />,
    path: "/paiements",
    text: "Paiements",
  },
  {
    activeIcon: <IoBookmark />,
    defaultIcon: <IoBookmarkOutline />,
    path: "/matieres",
    text: "Matières",
  },
  {
    activeIcon: <IoFingerPrint />,
    defaultIcon: <IoFingerPrintOutline />,
    path: "/pointage",
    text: "Pointage des profs",
  },
  {
    activeIcon: <IoAnalytics />,
    defaultIcon: <IoAnalyticsOutline />,
    path: "/compta",
    text: "Comptabilité",
  },
];

const Navbar = () => {
  const { toggleMenu, isMenuOpen } = useContext(ClassContext);
  const navigate = useNavigate();

  const handleNavigation = (e, path) => {
    e.preventDefault();
    toggleMenu();
    // alert(path);
    navigate(path);
  };

  return (
    <nav className={isMenuOpen ? "nav active" : "nav"}>
      <div className="top">
        <NavLink to="/home" className="logo">
          C
        </NavLink>
        <div className="close hidden" onClick={toggleMenu}>
          +
        </div>
      </div>

      <div className="links">
        {navList.map((nav, index) => (
          <NavLink
            to={nav.path}
            key={index}
            onClick={(e) => handleNavigation(e, nav.path)}
          >
            <div className="icon default">{nav.defaultIcon}</div>
            <div className="icon active">{nav.activeIcon}</div>

            <span className="full-text text">{nav.text}</span>
            <span className="one-word text">{nav.text.split(" ")[0]}</span>
          </NavLink>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
