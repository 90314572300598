//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken } from "./upload";

const endpoint = "paiement";
const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function addPaiment(body, refetchMatPaie, setIsSearching, setSucceededPayment) {
    //  console.log(body)
    const reponse = await uploadToken(endpoint, body, headers);

    if (reponse.ok == true) {
        setSucceededPayment(true);
        setIsSearching("notsearching"); //// apres payer is ok
        refetchMatPaie();
        console.log("les paiments fonctionne bien");


    }
    ///// c'est pour if payemnt == ok car on a une erreur 500

    ///// c'est pour if payemnt == ok car on a une erreur 500

}

export { addPaiment };
