import React, { useContext, useEffect, useRef, useState } from "react";
import SubSidebar from "../../layouts/SubSidebar";
import "../../assets/css/notes.css";
import Navbar from "../../layouts/Navbar";
import Header from "../../layouts/Header";
import ClassesList from "../../layouts/ClassesList";
import { results } from "./../../data/List";
import NotesList from "./NotesList";
import { ClassContext } from "../../contexts/ClassContext";
import NotesStats from "./NotesStats";
import DropdownFilter from "../../components/ui/DropdownFilter";
import {
  GetEvalMatierQuery,
  GetMatClasseQuery,
  GetNotesQuery,
} from "../../API/react_query/react_query";
import isEqual from "lodash/isEqual";
import BulletinViewer from "./BulletinViewer";
import Loading from "../../layouts/Loading";
import { minMaxAvg } from "../../utils/fonctions";
import { IoArrowForward } from "react-icons/io5";

const Notes = () => {
  const [switched, setSwitched] = useState(false);
  const [showBulletin, setShowBulletin] = useState(false);
  const [bulletins, setBulletins] = useState(false);
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);
  const [currentMatiere, setCurrentMatiere] = useState(0);
  const [currentEval, setCurrentval] = useState(0);
  const [test, setTest] = useState("devoir1");
  const [subject, setSubject] = useState("Mathématiques");
  const [donneDiag, setDonneDiagram] = useState([]);

  const afficherBulletin = () => {
    setShowBulletin(true);
  };

  const fermerBulletin = () => {
    setShowBulletin(false);
  };

  const {
    isError: isErrorMat,
    isLoading: isLoadingMat,
    error: errorMat,
    isFetching: isFetchingMat,
    data: dataMat,
  } = GetMatClasseQuery(currentClass, anneeScol);

  // Add error handling and logging
  useEffect(() => {
    if (isErrorMat) {
      console.error("Error fetching matières:", errorMat);
    }
  }, [isErrorMat, errorMat]);

  const {
    isError: isErrorEval,
    isLoading: isLoadingEval,
    error: errorEval,
    refetch: refetchEval,
    isFetching: isFetchingEval,
    data: dataEval,
  } = GetEvalMatierQuery(currentMatiere, anneeScol);
  const {
    isError: isErrorNote,
    isLoading: isLoadingNote,
    error: errorNote,
    refetch: refetchNote,
    isFetching: isFetchingNote,
    data: dataNote,
  } = GetNotesQuery(currentEval);

  // console.log("les matieres d'une classe ", dataMat);
  // console.log("les notes sont ", dataNote);

  const prevListRef = useRef();

  useEffect(() => {
    if (!isEqual(prevListRef.current, dataMat)) {
      // Code à exécuter si la valeur de la liste a changé
      if (dataMat) {
        if (dataMat.length > 0) {
          setCurrentMatiere(dataMat[0].id);
        }

        //refetchEval();
      }
    }
    // Mettre à jour la référence de la liste précédente
    prevListRef.current = dataMat;
  }, [dataMat]);

  useEffect(() => {
    if (!isEqual(prevListRef.current, dataEval)) {
      // Code à exécuter si la valeur de la liste a changé
      if (dataEval?.data.length > 0) {
        // console.log("les evaluations", dataEval);
        setCurrentval(dataEval.data[0].id);
      } else {
        setCurrentval(null);
      }
    }
    // Mettre à jour la référence de la liste précédente
    prevListRef.current = dataEval;
  }, [dataEval]);

  const createChartData = (list) => {
    var min = 0;
    var mean = 0;
    var max = 0;

    list.forEach((item) => {
      // console.log(item);
      if (item < 8) {
        min++;
      } else if (item >= 8 && item <= 14) {
        mean++;
      } else {
        max++;
      }
    });
    return [min, mean, max];
  };

  // LISTE EN FONCTION DE LA MATIERE
  var newList = results[test].filter((item) => item.subject == subject && item);

  // console.log("la listes des notes :", newList);

  const updateList = (id) => {
    // fetch
    // console.log("vtff");
  };

  const switchPages = (page) => {
    page == "Notes" ? setBulletins(false) : setBulletins(true);
  };

  useEffect(() => {
    setDonneDiagram(dataNote ? dataNote.data.map((item) => item.valeur) : []);

    // console.log("testtttttttttt", donneDiag);
  }, [dataNote]);

  // console.log("dataEval aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa : " + dataEval.);

  const switchClass = () => {
    if (switched) return "main-content notes switcher switched";
    else return "main-content notes switcher";
  };

  return (
    <>
      <Navbar />
      <Header title={"Notes de la " + currentClassName} />
      <ClassesList refetchFn={updateList} />

      <main>
        <SubSidebar
          filterList={["Notes", "Bulletins"]}
          dropdown={false}
          filterFn={switchPages}
        />

        <div className={switchClass()}>
          {bulletins ? (
            <div className="vide medium">
              Toutes les notes ne sont pas disponibles pour pouvoir générer des
              bulletins
            </div>
          ) : (
            <div className="list first-page">
              <div className="filter__section">
                <div className="slider">
                  {isLoadingMat ||
                  !dataMat ||
                  JSON.stringify(dataMat) === "[]" ? (
                    <div>- - - </div>
                  ) : isErrorMat ? (
                    <div>
                      Erreur lors du chargement des matières. Veuillez
                      réessayer.
                    </div>
                  ) : (
                    <DropdownFilter
                      list={[
                        ...new Set(
                          dataMat.map((item) => ({
                            id: item.id,
                            titre: item.libelle,
                          }))
                        ),
                      ]}
                      setCurrentMatiere={setCurrentMatiere}
                    />
                  )}

                  {!dataEval ||
                  dataEval?.length === 0 ||
                  JSON.stringify(dataMat) === "[]" ? (
                    <div>- - -</div>
                  ) : (
                    <>
                      {dataEval && (
                        <DropdownFilter
                          list={[
                            ...new Set(
                              dataEval.data.map((item) => ({
                                id: item.id,
                                titre: item.nom,
                              }))
                            ),
                          ]}
                          setCurrentMatiere={setCurrentval}
                        />
                      )}
                    </>
                  )}

                  <div className="switch-btn" onClick={() => setSwitched(true)}>
                    <span className="text">Statistiques</span>
                    <span className="icon">
                      <IoArrowForward />
                    </span>
                  </div>
                </div>
              </div>

              {dataEval == undefined ||
              dataNote == undefined ||
              dataMat == undefined ||
              JSON.stringify(dataMat) === "[]" ? (
                <div className="vide small">
                  Les évaluations ne sont pas encore disponibles
                </div>
              ) : (
                <NotesList
                  results={[
                    ...new Set(
                      dataNote.data.map((item) => ({
                        id_eleve: item.eleve.eleve.id,
                        matricule: item.eleve.eleve.matricule,
                        note: item.valeur,
                        nom_complet: item.eleve.eleve.nom_complet,
                        id_evaluation: item.evaluation.id,
                        id_note: item.id,
                        id_inscription: item.inscription_id,
                      }))
                    ),
                  ]}
                  test={test}
                  bulletins={bulletins}
                  refetcFn={refetchNote}
                />
              )}
              {isLoadingEval ||
                isLoadingNote ||
                (isLoadingMat && <Loading size="medium" />)}
            </div>
          )}

          {/* ===================== STATISTIQUES ===================== */}

          {donneDiag?.length > 0 ? (
            <NotesStats
              chartData={createChartData(donneDiag)}
              moyenneNote={minMaxAvg(donneDiag)}
              bulletins={bulletins}
              afficherBulletin={afficherBulletin}
              setSwitched={setSwitched}
            />
          ) : (
            <div className={"empty"}></div>
          )}
        </div>
      </main>

      {showBulletin && <BulletinViewer onClose={fermerBulletin} />}
    </>
  );
};

export default Notes;
