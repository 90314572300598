import React, { useContext, useEffect, useState } from "react";
import { ClassContext } from "../contexts/ClassContext";
import { GetClasseQuery } from "../API/react_query/react_query";
import {
  generateClassName,
  compareClasses,
  formatNiveauNom,
} from "../utils/orderClasses";
import Loading from "./Loading";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

const ClassesList = ({ refetchFn }) => {
  const { data: data, isLoading } = GetClasseQuery();
  const { currentClass, currentClassName, changeClass } =
    useContext(ClassContext);
  const [activeClassId, setActiveClassId] = useState(currentClass);
  const [classes, setClasses] = useState([]);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (data?.classes) {
      const classesWithFullName = data.classes.map((item) => ({
        id: item.id,
        name: generateClassName(item),
        niveau: item.niveau.nom,
      }));

      // Trier les classes
      const sortedClasses = classesWithFullName.sort(compareClasses);

      // Formater les noms des niveaux après le tri
      const formattedClasses = sortedClasses.map((item) => ({
        ...item,
        name:
          formatNiveauNom(item.niveau) +
          item.name.substring(item.niveau.length),
      }));

      setClasses(formattedClasses);
    }
  }, [data]);

  if (isLoading) {
    return <Loading size="medium" />;
  }

  const handleClassClick = (id, className) => {
    setActiveClassId(id);
    changeClass(id, className);
    setOpened(false);
    // refetchFn(id);
  };

  const toggleOpen = () => {
    setOpened((prev) => !prev);
  };

  return (
    <>
      <article className={opened ? "classes opened" : "classes"}>
        <div className="slider">
          {classes.map(({ id, name }) => (
            <div
              key={id}
              className={`classe ${activeClassId === id ? "active" : ""}`}
              onClick={() => handleClassClick(id, name)}
            >
              {name}
            </div>
          ))}
        </div>
      </article>

      <div className={opened ? "mobile-tab opened" : "mobile-tab"}>
        <button className="add-class">
          <span className="icon">+</span>
          <span className="text">Nouvelle classe</span>
        </button>
        <div className="current-class">{currentClassName}</div>
        <button className="dropdown" onClick={toggleOpen}>
          <span className="icon opened">
            <IoChevronUpOutline />
          </span>
          <span className="icon closed">+{/* <IoChevronDownOutline /> */}</span>
        </button>
      </div>
    </>
  );
};

export default ClassesList;
