import React from "react";
import PopupBg from "./PopupBg";

const PopupForm = ({
  onClose,
  title,
  children,
  onSubmit,
  isLoading = false,
}) => {
  return (
    <PopupBg closeModal={onClose} opacity="transparent">
      <div className="popup__form evaluation__add__form">
        <h2>{title}</h2>

        <div className="fields-container">{children}</div>

        <div className="buttons">
          <button className="cancel" onClick={onClose}>
            Annuler
          </button>
          <button className="send" disabled={isLoading} onClick={onSubmit}>
            {title.split(" ")[0]}
          </button>
        </div>
      </div>
    </PopupBg>
  );
};

export default PopupForm;
