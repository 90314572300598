import React, { useContext, useEffect, useState } from "react";
import {
  IoCart,
  IoCheckmark,
  IoCheckmarkSharp,
  IoPerson,
} from "react-icons/io5";
import { addPaiment } from "../../API/fonctions/addPaiement";
import { ClassContext } from "../../contexts/ClassContext";
import { GetEleve, GetPaiementClasse } from "../../API/react_query/react_query";
import Loading from "../../layouts/Loading";
import { formatCurrencyCFA, getInitials } from "../../utils/fonctions";
import SuccessCard from "../../components/ui/SuccessCard";
import { generateClassName } from "../../utils/orderClasses";

const EspecePayment = ({ func }) => {
  const [succeededPayment, setSucceededPayment] = useState(false);

  const { currentClass, anneeScol } = useContext(ClassContext);
  const {
    isError: isErrorPaie,
    isLoading: isLoadingPaie,
    error: errorPaie,
    refetch: refetchMatPaie,
    isFetching: isFetchingPaie,
    data: dataPaie,
  } = GetEleve(currentClass);

  const [value, setValue] = useState("");
  const [isSearching, setIsSearching] = useState("notsearching"); // notsearching / searchyng / found
  const [studentFound, setStudentFound] = useState();
  const [action, setAction] = useState("mensuel");
  // const { currentClassName, currentClass, anneeScol } =
  //   useContext(ClassContext);
  const [isInscription, setIsInscription] = useState(false);

  // const [ montant, setMontant ] = useState('montant_mensuel');
  // monthIndex == 10 ? 'inscription' : 'mensualite'

  const [selectedMonth, setSelectedMonth] = useState();
  //console.log("le mois selectionner ",selectedMonth)

  useEffect(() => {
    if (isSearching == "found") {
      setSelectedMonth(
        months.filter((month) => !studentFound.mois.includes(month))[0]
      );
      //refetchMatPaie();
    }
  }, [isSearching]);

  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  // const filteredMonths = months.filter(month => !studentFound.mois.includes(month));
  // console.log(filteredMonths)

  const handleSelectMonth = (event) => {
    setSelectedMonth(event.target.value);
  };

  const result = (student) => {
    setStudentFound(student);
    setIsSearching("found");
  };

  useEffect(() => {
    if (action == "mensuel") {
      setIsInscription(false);
    }
    if (action == "inscription") {
      setIsInscription(true);
    }
  }, [action]);

  ////////////////////////////////ppppppppaa

  const payer = (e) => {
    e.preventDefault();

    const body = {
      montant: studentFound.frais_scolarité["montant_" + action],
      mois: selectedMonth,
      annee_scolaire_id: anneeScol,
      inscription_id: studentFound.inscription_id,
      commentaire: "test",
      is_inscription: isInscription,
    };

    const repons = addPaiment(
      body,
      refetchMatPaie,
      setIsSearching,
      setSucceededPayment
    );
  };

  const startSearching = () => {
    setIsSearching("searching");
    setSucceededPayment(false);
  };

  const showSuccessMsg = () => {
    setSucceededPayment(true);
    isSearching("notsearching");
  };
  // const hideSuccessMsg () => {
  //   setSucceededPayment(false)
  // }

  //////////////////////////////////////////

  return (
    <div className="main-content espece">
      <div className="ep-container">
        <div className={"search-bar " + isSearching}>
          <div className="input-box">
            <div className="icon">
              <IoPerson />
            </div>

            <input
              type="text"
              placeholder="Prénom de l'élève"
              onChange={(e) => setValue(e.target.value)}
              onFocus={startSearching}
              // onBlur={() => setIsSearching("notsearching")}
            />
          </div>

          {isSearching != "found" && (
            <div className="buttons">
              <button
                className={action == "inscription" ? "active" : undefined}
                onClick={() => setAction("inscription")}
              >
                Inscription
              </button>
              <button
                className={action == "mensuel" ? "active" : undefined}
                onClick={() => setAction("mensuel")}
              >
                Mensualité
              </button>
            </div>
          )}
        </div>

        {isSearching == "searching" && (
          <div className="recom">
            {dataPaie &&
              dataPaie.eleves?.map((item, index) => {
                if (value == "") {
                  return "";
                } else if (
                  item.eleve.nom_complet
                    .toLowerCase()
                    .includes(value.toLowerCase())
                ) {
                  return (
                    <div
                      className="res"
                      key={index}
                      onClick={() => result(item)}
                    >
                      <span className="icon">
                        <IoCheckmarkSharp />
                      </span>
                      <span className="matricule">{item.eleve.matricule}</span>
                      <span className="name">{item.eleve.nom_complet}</span>
                    </div>
                  );
                }
              })}
          </div>
        )}

        {isSearching == "found" && (
          <div className="cards">
            <div className="card">
              <header>
                <div className="icon">
                  <IoPerson />
                </div>
                Informations de l'élève
              </header>
              <div className="content">
                <div className="profile">
                  {getInitials(studentFound.eleve.nom_complet)}
                </div>
                <div className="more">
                  <h2 className="name">{studentFound.eleve.nom_complet}</h2>
                  <div className="info">
                    <div className="title">Matricule</div>
                    <div className="value">{studentFound.eleve.matricule}</div>
                  </div>
                  {/* <div className="info">
                  <div className="title">Classe</div>
                  <div className="value">{generateClassName(studentFound.classe)}</div>
                </div> */}
                </div>
              </div>
            </div>

            <div className="card">
              <header>
                <div className="icon">
                  <IoCart />
                </div>
                Montant de la mensualité
              </header>
              <div className="text">
                <div className="amount">
                  {formatCurrencyCFA(
                    studentFound.frais_scolarité["montant_" + action]
                  )}{" "}
                  FCFA
                </div>

                {!isInscription &
                (months.filter((month) => !studentFound.mois.includes(month))
                  .length >
                  0) ? (
                  <div className="select__section">
                    <div className="select__btn">
                      <select
                        id=""
                        value={selectedMonth}
                        onChange={handleSelectMonth}
                      >
                        {months
                          .filter((month) => !studentFound.mois.includes(month))
                          .map((month1, index) => (
                            <option key={index} value={month1}>
                              {month1.charAt(0).toUpperCase() + month1.slice(1)}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                ) : null}

                {months.filter((month) => !studentFound.mois.includes(month))
                  .length > 0 ? (
                  <button className="pay" onClick={(e) => payer(e)}>
                    Payer maintenant
                  </button>
                ) : (
                  <p>Tous les mois ont été réglés.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {succeededPayment && (
        <SuccessCard text="Paiement effectué avec succés !" />
      )}

      {/* {isLoadingPaie && <Loading />} */}
      {/* {!dataPaie || isLoadingPaie || (isFetchingPaie && <Loading size='small' />)} */}
    </div>
  );
};

export default EspecePayment;
