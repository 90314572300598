import React, { useEffect } from "react";
import PopupBg from "../../components/ui/modal/PopupBg";
import AdminsList from "./AdminsList";
import AdminsNew from "./AdminsNew";
import ShowAdminDetails from "./ShowAdminDetails";
import "../../assets/css/notifications.css";
import { IoPersonAddOutline } from "react-icons/io5";
import { useState } from "react";

const Admins = ({ closeModal }) => {
  const [addFormOpened, setAddFormOpened] = useState(false);
  const [adminDetails, setAdminDetails] = useState("");

  const deleteAdminDetails = () => {
    setAdminDetails("");
  };
  // useEffect(()=>{

  // },[addFormOpened])
  const putAdminDetails = () => {
    setAdminDetails("qqch");
  };

  const openAddForm = () => {
    setAddFormOpened(true);
    deleteAdminDetails();
  };

  const closeAddForm = () => {
    setAddFormOpened(false);
  };

  return (
    <PopupBg closeModal={closeModal} opacity="transparent">
      <div className="Admins">
        {!addFormOpened ? (
          <AdminsList onClose={closeModal} onDetail={putAdminDetails} />
        ) : (
          <AdminsNew onPrev={closeAddForm} />
        )}

        {adminDetails != "" && <ShowAdminDetails />}

        {!addFormOpened && (
          <button className="button__add" onClick={openAddForm}>
            <IoPersonAddOutline />
          </button>
        )}
      </div>
    </PopupBg>
  );
};

export default Admins;
