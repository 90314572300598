import React, { useContext } from "react";
import MainAdminSection from "./MainAdminSection";
import AdminsSection from "./AdminsSection";
import { ClassContext } from "../../contexts/ClassContext";
import { GetMembreAdmin } from "../../API/react_query/react_query";
import { renamePrenomToNomComplet } from "../../utils/fonctions";

const AdminsList = ({ onClose }) => {
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);

  const {
    isError: isErrorMenAdmin,
    isLoading: isLoadingMenAdmin,
    error: errorMenAdmin,
    refetch: refetchMenAdmin,
    isFetching: isFetchingMenAdmin,
    data: dataMenAdmin,
  } = GetMembreAdmin(currentClass);
  console.log("les membre de l'admin", dataMenAdmin);
  return (
    <div className="Notifications__list">
      <div className="head">
        Administration{" "}
        <span className="icon" onClick={onClose}>
          +
        </span>
      </div>

      {isLoadingMenAdmin || !dataMenAdmin ? (
        <AdminsSection categorie={"Surveillants"} list={[]} />
      ) : (
        <div className="all__admins">
          <MainAdminSection />
          <AdminsSection
            categorie={"Surveillants"}
            list={dataMenAdmin.attaches}
          />
          <AdminsSection
            categorie={"Gestionnaires ou caissiers"}
            list={dataMenAdmin.caissiers}
          />
        </div>
      )}

      {/* <NotifBox onDetail={onDetail} /> */}
    </div>
  );
};

export default AdminsList;
