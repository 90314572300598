import React, { useState, useContext, useEffect } from "react";
import DropdownFilter from "../../components/ui/DropdownFilter";
import DayPlanBox from "./DayPlanBox";
import AddButton from "../../components/ui/button/AddButton";
import EvaluationAddForm from "./EvaluationAddForm";
import { GetEvalClasse } from "../../API/react_query/react_query";
import { ClassContext } from "../../contexts/ClassContext";
import { groupByDate } from "../../utils/fonctions";
import Loading from "../../layouts/Loading";
import { formatDate } from "../../utils/dateConversion";

const Evaluations = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);

  const {
    isError: isErrorEval,
    isLoading: isLoadingEval,
    error: errorEval,
    refetch: refetchEval,
    isFetching: isFetchingEval,
    data: dataEval,
  } = GetEvalClasse(currentClass, anneeScol);

  useEffect(() => {
    refetchEval();
  }, [isOpen]);

  useEffect(() => {
    if (dataEval?.data) {
      setEvaluations(groupByDate(dataEval.data));
    }
  }, [dataEval]);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="evaluations">
      <h3>Liste des évaluations à venir</h3>
      {evaluations && evaluations.length > 0 ? (
        evaluations.map((evaluationsParDate) => (
          <div key={evaluationsParDate.date}>
            <h3>{formatDate(evaluationsParDate.date)}</h3>
            {evaluationsParDate.items.map((evaluation) => (
              <DayPlanBox
                key={evaluation.id} // Assurez-vous d'utiliser une clé unique pour chaque DayPlanBox
                heur_debut={evaluation.heure_debut.slice(0, -3)}
                heur_fin={evaluation.heure_fin.slice(0, -3)}
                matier={evaluation.matiere.libelle}
                commands={false}
                // Passez d'autres props nécessaires à DayPlanBox
              />
            ))}
          </div>
        ))
      ) : (
        <div className="empty medium">Aucune évaluation à venir !</div>
      )}

      <AddButton text="Nouvelle évaluation" onOpen={setIsOpen} />

      {isLoadingEval && <Loading size="medium" />}
      {isOpen && <EvaluationAddForm onClose={closeModal} />}
    </div>
  );
};

export default Evaluations;
