let saveToken = (token) => {
  localStorage.setItem("token", token);
};

let saveEcole = (ecole) => {
  localStorage.setItem("ecole", ecole);
};

let logOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("ecole");
};

let isLoged = () => {
  let token = localStorage.getItem("token");
  return !!token; // retourne true si le token n'est pas vide, false sinon
};

let getToken = () => {
  let token = localStorage.getItem("token");
  return token;
};

let getEcole = () => {
  let ecole = localStorage.getItem("ecole");
  return ecole;
};

let getIdEecole = () => {
  let id = localStorage.getItem("id");
  return id;
};

let saveId = (id) => {
  localStorage.setItem("id", id);
};

export const accountService = {
  saveToken,
  logOut,
  isLoged,
  saveId,
  getToken,
  getIdEecole,
  saveEcole,
  getEcole,
};
