//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken } from "./upload";

const endpoint = "cours";
const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function addCours(body, onClose, setMessageErreur, refetchCours) {
    const reponse = await uploadToken(endpoint, body, headers);


    if (reponse.ok == true) {
        onClose();
        refetchCours();
        console.log("cours ajouter avec succe")
      
    }
    else {
        console.log("erreur ", reponse)
        const errorDetails = await reponse.json();
        console.error("Erreur serveur:", reponse.status, errorDetails);
        // setIsErreur(true)
        setMessageErreur(errorDetails.message)
    }
}

export { addCours };
