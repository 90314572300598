import React, { useContext, useEffect } from "react";
import ElementWithActionBtn from "../../components/user/ElementWithActionBtn";
import { IoBookmark, IoTrashOutline } from "react-icons/io5";
import { GetMatClasseQuery } from "../../API/react_query/react_query";
import { ClassContext } from "../../contexts/ClassContext";
import '../../assets/css/subjects.css'

const SubjectsList = ({ btnFn, secondBtnFn,closeFn }) => {

  const { currentClass, anneeScol } = useContext(ClassContext);
  const { data: subjects, isLoading: isLoadingSubjects,refetch:refetchSubjects } = GetMatClasseQuery(
    currentClass,
    anneeScol
  );

  // console.log(subjects)
  useEffect(() => {
    refetchSubjects();
  }, [closeFn,btnFn]);

  return (
    <div className="subjets__list">
      <h3>Liste des matières de la classe</h3>
      <div className="grid-2">
        {/* Mapper subjects ... avec subject?.map(..) */}
        {subjects?.map((subject) => (
          <ElementWithActionBtn
          key={subject.id}
          element={{
              icon: <IoBookmark />,
              element: { id: subject.id, nom_complet: subject.libelle, coefficient: subject.coefficient, professeur: subject.professeur },
              subtitle: `coef :${subject?.coefficient} - ${subject?.professeur ? subject?.professeur?.nom_complet : "Pas de professeur"}`,

            }}
            btn={{
              text: "Modifier",
              fn: btnFn,
            }}
            secondBtn={{
              icon: <IoTrashOutline />,
              fn: secondBtnFn,
            }}
          />)
        )}

      </div>
    </div>
  );
};

export default SubjectsList;
