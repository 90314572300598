import React, { useState, useContext, useEffect } from "react";
import {
  IoAlarmOutline,
  IoCalendarOutline,
  IoChevronDown,
  IoLockClosed,
  IoPencil,
  IoTodayOutline,
} from "react-icons/io5";
import DayPlanBox from "./DayPlanBox";
import StoppingCoursesPopup from "./StoppingCoursesPopup";
import ReportingCoursesPopup from "./ReportingCoursesPopup";
import SelectableBoxes from "../../components/form/SelectableBoxes";
import { GetEmploiByDateAndClasse } from "../../API/react_query/react_query";
import { ClassContext } from "../../contexts/ClassContext";
import {
  getDateDemainForAPI,
  getDAteChoisforAPI,
  regrouperCoursEvaluations,
} from "../../utils/fonctions";
import Loading from "../../layouts/Loading";

const DayPlanning = () => {
  ///api //
  const [selectedDate, setSelectedDate] = useState("2024-07-26");
  const [select, setSelect] = useState(1);
  const [dateChoi, setDateChoix] = useState("");
  const date2daye = getDateDemainForAPI();
  const [donneeModifCours, setDonneeModifCours] = useState({});
  // console.log("la date 2daye  ",date2daye);
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);
  //console.log("l'annee scolaire de alla aa a a    a a a a  a  a  aa   a  a a a a",anneeScol)

  let datee = getDAteChoisforAPI(select, selectedDate);
  const {
    isError: isErrorEmp,
    isLoading: isLoadingEmp,
    error: errorNote,
    refetch: refetchEmp,
    isFetching: isFetchingEmp,
    data: dataEmp,
  } = GetEmploiByDateAndClasse(currentClass, datee, anneeScol);
  //console.log(datee)
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const selectables = [
    { id: 1, title: "Aujourd'hui" },
    { id: 2, title: "Demain" },
    { id: 3, title: "Autre jour" },
  ];
  const [isStopping, setIsStopping] = useState(false);
  const [isReporting, setIsReporting] = useState(false);

  const closeStop = () => {
    setIsStopping(false);
  };

  const stopping = () => {
    setIsStopping(true);
  };

  const closeReport = () => {
    setIsReporting(false);
  };

  const reportping = (donne) => {
    setDonneeModifCours(donne);
    setIsReporting(true);
  };

  useEffect(() => {
    refetchEmp();
  }, [isReporting]);

  return (
    <article className="day-planning">
      <div className="commands">
        <div className="slider">
          <SelectableBoxes leBoxes boxes={selectables} onSelect={setSelect}>
            <input
              type="date"
              className="box"
              name="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </SelectableBoxes>
        </div>
      </div>

      <h3>Liste des cours et evaluations</h3>

      {regrouperCoursEvaluations(dataEmp, datee).length > 0 ? (
        regrouperCoursEvaluations(dataEmp, datee).map((plan, index) => (
          <DayPlanBox
            key={index}
            onStop={stopping}
            onReport={reportping}
            heur_debut={plan.heure_debut.slice(0, -3)}
            heur_fin={plan.heure_fin.slice(0, -3)}
            matier={plan.matiere?.libelle}
            evaluation={plan.nom ? plan.nom : null}
            commands={plan.nom ? false : false}
            id_modif={plan.id}
          />
        ))
      ) : (
        <div className="empty small">Aucun cours prévu pour cette journée</div>
      )}

      {isLoadingEmp && <Loading size="small" />}

      {isStopping && <StoppingCoursesPopup onClose={closeStop} />}
      {isReporting && (
        <ReportingCoursesPopup
          onClose={closeReport}
          donnees={donneeModifCours}
        />
      )}
    </article>
  );
};

export default DayPlanning;
