import React, { useState, useEffect, useContext } from "react";
import { IoStarOutline, IoPersonOutline } from "react-icons/io5";
import PopupForm from "../../components/ui/modal/PopupForm";
import DropdownList from "../../components/form/DropdownList";
import { ClassContext } from "../../contexts/ClassContext";
import { GetProfs } from "../../API/react_query/react_query";
import { modierMatie } from "../../API/fonctions/modierMatie";

// Commented out React Query import
// import { GetAllprofs } from "../../API/react_query/react_query";

const EditSubjectPopup = ({ onClose, subject, refetchSubjects }) => {
  const [coefficient, setCoefficient] = useState(
    subject?.coefficient.toString()
  );
  
  const [teacherId, setTeacherId] = useState(
    subject.professeur?.id?.toString() || ""
  );

  const { currentClass, anneeScol } = useContext(ClassContext);
  const { data: professeur, isLoading: isLoadingProf } = GetProfs(anneeScol);

  // Commented out React Query hook
  // const { data: teachers, isLoading: isLoadingTeachers } =   GetAllprofs();

  // Fake teachers data

  // console.log("subjet ",subject)
  const teachers = professeur?.data?.map((prof) => ({
    id: prof.prof.id,
    content: prof.prof.nom_complet,
  }));

  const isLoadingTeachers = false;

  useEffect(() => {
    if (subject) {
      setCoefficient(subject.coefficient.toString());
      setTeacherId(subject.professeur?.id?.toString() || "");
    }
  }, []);

  const handleSubmit = () => {
    const updatedSubject = {
      "libelle": subject.nom_complet,
      "description": "la vie est belle",
      "coef": coefficient,
      "classe_id":currentClass,
      "professeur_id": teacherId,
      "annee_id":anneeScol,

    };

    // TODO: Implement the actual update logic here
    // console.log("Updating subject:", updatedSubject);
    // For now, we'll just close the popup and refetch subjects
    modierMatie(updatedSubject,onClose,subject.id)

  };

  const coefficients = [
    { id: "1", content: "Coef : 1" },
    { id: "2", content: "Coef : 2" },
    { id: "3", content: "Coef : 3" },
    { id: "4", content: "Coef : 4" },
    { id: "6", content: "Coef : 6" },
    { id: "7", content: "Coef : 8" },
    { id: "8", content: "Coef : 8" },
    { id: "9", content: "Coef : 10" },
    { id: "10", content: "Coef : 10" },

  ]


  return (
    <PopupForm
      onClose={onClose}
      title={`Modifier ${subject.nom_complet}`}
      onSubmit={handleSubmit}
    >
      <DropdownList
        icon={<IoStarOutline />}
        items={coefficients}
        value={subject.coefficient}
        setValue={setCoefficient}
        placeholder="Sélectionner un coefficient"
      />
      <DropdownList
      icon={<IoPersonOutline />}
      items={teachers}
      value={subject.professeur?.id}
      setValue={setTeacherId}
      placeholder="Sélectionner un enseignant"
      isLoading={isLoadingTeachers}
    />

    </PopupForm>
  );
};

export default EditSubjectPopup;
