import React, { useState } from "react";
import { Header, Navbar, SubSidebar } from "../../layouts";
import "../../assets/css/pointage.css";
import UserWithActionBtn from "../../components/user/UserWithActionBtn";
import Presences from "./Presences";
import Salaires from "./Salaires";

const Pointage = () => {
  const [activePage, setActivePage] = useState("Présences");

  const togglePage = (page) => {
    setActivePage(page);
  };
  return (
    <>
      <Navbar />
      <Header title="Pointage des professeurs" />

      <div className="pointage main-fullwidth">
        <SubSidebar
          title="Comptabilité"
          filterList={["Présences", "Horaires"]}
          filterFn={togglePage}
        />

        {activePage == "Présences" ? <Presences /> : <Salaires />}
      </div>
    </>
  );
};

export default Pointage;
