import {useQuery} from "@tanstack/react-query";
import {getData} from "../fonctions/getData";
import {accountService} from "../service/account.service";


export const GetUserQuery = () => {
    const token = accountService.getToken()
    const endPoint = "user"
    const {isLoading: isLoadingUser, error: errorUser, data, isError} = useQuery({
        queryKey: ['getDataUser'],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    //console.log("user",data)
    return {isLoading: isLoadingUser, error: errorUser, data, isError};
};

export const GetClasseQuery = () => {

    const id_ecol = accountService.getEcole()
    const token = accountService.getToken()
    const endPoint = "ecole/" + id_ecol
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetClasseQuery", token],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetElevesQuery = (id_eleve) => {

    const token = accountService.getToken()
    const endPoint = "classe/" + id_eleve
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ['getDataEleve'],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetMatClasseQuery = (id_classe, annee) => {

    const token = accountService.getToken()
    const endPoint = "matieres/classe/" + id_classe + "/annee/" + annee
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetMatClasseQuery", id_classe, annee],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetEvalMatierQuery = (id_matier, annee) => {

    const token = accountService.getToken()
    const endPoint = "evaluations/matiere/" + id_matier + "/annee/" + annee
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetEvalMatierQuery", id_matier, annee],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,

    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetNotesQuery = (id_eval) => {

    const token = accountService.getToken()
    const endPoint = "notes/evaluation/" + id_eval
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetNotesQuery", id_eval],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,

    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetAlleNotesQuery = (id_eval) => {

    const token = accountService.getToken()
    const endPoint = "notes/evaluation/" + id_eval
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetAlleNotesQuery", id_eval],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
        retry: 5, // Réessaie la requête jusqu'à 3 fois en cas d'échec
        retryDelay: 500, // Délai de 2 secondes entre chaque tentative
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetEmploiByDateAndClasse = (id_classe, date, annee) => {

    const token = accountService.getToken()
    const endPoint = "classe/" + id_classe + "/date/" + date + "/annee/" + annee
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetEmploiByDateAndClasse", id_classe, date, annee],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetEmploiInterAndClasse = (id_classe, annee, dateDeb, dateFin) => {

    const token = accountService.getToken()
    const endPoint = "annee/" + annee + "/classe/" + id_classe + "/debut/" + dateDeb + "/fin/" + dateFin
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetEmploiInterAndClasse", id_classe, dateDeb, dateFin, annee],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetAbsencesClasse = (id_classe, annee) => {

    const token = accountService.getToken()
    const endPoint = "absence/nonJustifier/classe/" + id_classe + "/annee/" + annee
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetAbsencesClasse", id_classe, annee],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetEvalClasse = (id_classe, annee) => {

    const token = accountService.getToken()
    const endPoint = "evaluations/classe/" + id_classe + "/annee/" + annee
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetEvalClasse", id_classe, annee],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetPaiementClasse = (id_classe, mois, annee) => {

    const token = accountService.getToken()
    const endPoint = "paiement/classe/" + id_classe + "/mois/" + mois + "/annee/" + annee
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetPaiementClasse", id_classe, annee, mois],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetAnnee = () => {

    const token = accountService.getToken()
    const endPoint = "anneescolaires"
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetEleve = (id_classe) => {

    const token = accountService.getToken()
    const endPoint = "classe/" + id_classe
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetEleve", id_classe],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};


export const GetSemes = (annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint = "semestres/ecole/" + id_ecole + "/annee/" + annee
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetSemes", annee],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetAllinscriptions = (annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint = "total/montant/inscription/ecole/" + id_ecole + "/annee/" + annee

    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetAllinscriptions", annee, id_ecole],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetAllMensualite = (annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint = "total/montant/mensualite/ecole/" + id_ecole + "/annee/" + annee

    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetAllMensualite", annee, id_ecole],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetAllEleves = (annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint = "total/eleves/ecole/" + id_ecole + "/annee/" + annee

    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetAllEleves", annee, id_ecole],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetAllprofs = (annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint = "total/profs/ecole/" + id_ecole + "/annee/" + annee

    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetAllprofs", annee, id_ecole],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetAllsurveillants = (annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint = "total/surveillants/ecole/" + id_ecole + "/annee/" + annee

    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetAllsurveillants", annee, id_ecole],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetPointages = (date, annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint = "pointage/ecole/" + id_ecole + "/annee/" + annee + "/date/" + date

    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetPointages", annee, id_ecole, date],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};

export const GetMembreAdmin = (annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint = "adiministration/ecole/" + id_ecole

    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetMembreAdmin", annee, id_ecole],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetInfosEleve = (di_eleve) => {

    const token = accountService.getToken()
    const endPoint = "eleves/" + di_eleve
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetInfosEleve", di_eleve],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};
export const GetProfs = ( annee) => {

    const token = accountService.getToken()
    const id_ecole = accountService.getEcole()
    const endPoint ="profs/ecole/"+id_ecole+"/annee/"+annee
    const {isError, isLoading, error, refetch, isFetching, data} = useQuery({
        queryKey: ["GetProfs", id_ecole, annee],
        queryFn: () => getData(endPoint, token),
        enabled: !!token,
    });
    // console.log("classe ",data)
    return {isError, isLoading, error, refetch, isFetching, data};
};


