import React from "react";
import {
  IoCalendar,
  IoCalendarOutline,
  IoCall,
  IoDownload,
  IoLocation,
  IoPeople,
  IoPerson,
} from "react-icons/io5";
import PopupBg from "../../components/ui/modal/PopupBg";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { formatPhoneNumber } from "../../utils/fonctions";
import { formatDate } from "../../utils/dateConversion";

const StudentInfos = ({ studentClicked, closeModal }) => {
  // const initials = studentClicked.prenom.substr(0, 1)+studentClicked.nom.substr(0, 1);

  // console.log("eleve selectionner ",studentClicked)

  const handleClose = () => {
    closeModal();
  };

  return (
    <PopupBg closeModal={closeModal} opacity="transparent">
      {/* <animated.div style={animated}> */}
      <div className="modal">
        <div className="close" onClick={closeModal}>
          +
        </div>
        <div className="profile__box">
          <div className="icon">
            <IoPerson />
          </div>

          <div className="name__box">
            <div className="name">{studentClicked.nom_complet}</div>
            <div className="matricule">{studentClicked.matricule}</div>
          </div>
        </div>

        <div className="infos">
          <h2>Informations de l'élève</h2>

          <div className="infos__container">
            <div className="infos__box">
              <div className="icon">
                <IoCalendar />
              </div>
              <div className="text">
                <div className="title">Date de naissance</div>
                <div className="value">
                  {formatDate(studentClicked?.date_naissance, true)}
                </div>
              </div>
            </div>

            {studentClicked.telephone && (
              <div className="infos__box">
                <div className="icon">
                  <IoCall />
                </div>
                <div className="text">
                  <div className="title">Numéro de téléphone de l'élève</div>
                  <div className="value">
                    {formatPhoneNumber(studentClicked.telephone)}
                  </div>
                </div>
              </div>
            )}

            <div className="infos__box">
              <div className="icon">
                <IoLocation />
              </div>
              <div className="text">
                <div className="title">Adresse</div>
                <div className="value">{studentClicked.adresse}</div>
              </div>
            </div>

            {studentClicked.tuteur.lien_parente && (
              <div className="infos__box">
                <div className="icon">
                  <IoPeople />
                </div>
                <div className="text">
                  <div className="title">Tuteur</div>
                  <div className="value tuteur">
                    {studentClicked.tuteur.lien_parente}
                  </div>
                </div>
              </div>
            )}

            <div className="infos__box">
              <div className="icon">
                <IoPerson />
              </div>
              <div className="text">
                <div className="title">Nom du tuteur</div>
                <div className="value">
                  {studentClicked?.tuteur?.nom_complet}
                </div>
              </div>
            </div>

            <div className="infos__box">
              <div className="icon">
                <IoCall />
              </div>
              <div className="text">
                <div className="title">Numéro de téléphone du tuteur</div>
                <div className="value">
                  {formatPhoneNumber(studentClicked.tuteur.telephone)}
                </div>
              </div>
            </div>
          </div>

          <h2 className="title-documents">Documents de l'élève</h2>

          <div className="infos__container">
            <div className="infos__box download__box">
              <div className="icon">
                <IoDownload />
              </div>
              <Link>Certificat de scolarité</Link>
            </div>

            <div className="infos__box download__box">
              <div className="icon">
                <IoDownload />
              </div>
              <Link>Certificat de fréquentation</Link>
            </div>
          </div>

          {/* <div className="buttons">
                            <button className="cancel" onClick={closeModal}>Fermer</button>
                            <button className="edit">Modifier </button>
                        </div> */}
        </div>
      </div>
    </PopupBg>
  );
};

export default StudentInfos;
