import React, { useContext, useState } from "react";
import {
  IoArrowBack,
  IoBagAddOutline,
  IoExitOutline,
  IoKeyOutline,
  IoMailOpen,
  IoMailOpenOutline,
  IoPersonOutline,
  IoPhoneLandscapeOutline,
  IoPhonePortraitOutline,
} from "react-icons/io5";
import { ClassContext } from "../../contexts/ClassContext";
import { adAdmin } from "../../API/fonctions/adAdmin";

const AdminsNew = ({ onPrev }) => {
  const [fonction, setFonction] = useState("");
  const [nomComplet, setNomComplet] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [motDePasse, setMotDePasse] = useState("");
  const { currentClassName, currentClass, anneeScol } = useContext(ClassContext);


  const newAdmin = (e,) => {
    e.preventDefault();

    const body = {
      "name": nomComplet,
      "telephone": telephone,
      "password": motDePasse,
      "login": telephone,
      "email": email,
      "annee_id": anneeScol
    };

    adAdmin(body, fonction, onPrev)

  };
  return (
    <div className="Notifications__new">
      <h1>
        <span className="icon" onClick={onPrev}>
          <IoArrowBack />
        </span>
        Nouveau compte
      </h1>

      <form>
        <p className="input__box">
          <div className="icon">
            <IoBagAddOutline />
          </div>
          <select value={fonction} onChange={(e) => setFonction(e.target.value)}>
            <option value="" disabled selected>- - Choisir fonction - -</option>
            <option value="1">Surveillant</option>
            <option value="2">Gestionnaire</option>
          </select>
        </p>

        <p className="input__box">
          <div className="icon">
            <IoPersonOutline />
          </div>
          <input type="text" placeholder="Prénom et nom ..." value={nomComplet}
            onChange={(e) => setNomComplet(e.target.value)}
          />
        </p>

        <p className="input__box">
          <div className="icon">
            <IoPhonePortraitOutline />
          </div>
          <input type="number" placeholder="Téléphone (ex: 70234567)  ..."
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)} />
        </p>

        <p className="input__box">
          <div className="icon">
            <IoMailOpenOutline />
          </div>
          <input type="mail" placeholder="Adresse e-mail ..."
            value={email}
            onChange={(e) => setEmail(e.target.value)} />
        </p>

        <p className="input__box">
          <div className="icon">
            <IoKeyOutline />
          </div>
          <input type="text" placeholder="Mot de passe ..."
            value={motDePasse}
            onChange={(e) => setMotDePasse(e.target.value)}
          />
        </p>

        <p className="send__box">
          <button onClick={(e) => { newAdmin(e) }}>Ajouter</button>
        </p>
      </form>
    </div>
  );
};

export default AdminsNew;
