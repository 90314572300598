export const getDteforAPI = () => {
  let maDate = new Date(); // Vous pouvez initialiser cette date avec une valeur spécifique si nécessaire

  // Récupérer l'année
  let annee = maDate.getFullYear();

  // Récupérer le mois (attention : les mois commencent à 0 pour janvier)
  let mois = maDate.getMonth() + 1; // Ajoutez 1 car getMonth() retourne un index de 0 à 11

  // Récupérer le jour du mois
  let jour = maDate.getDate();

  mois = mois < 10 ? "0" + mois : mois;
  jour = jour < 10 ? "0" + jour : jour;

  return annee + "-" + mois + "-" + jour;
};

export function timeForPointage(time) {
  const currentTime = new Date(); // Heure actuelle

  // Séparer 'time' en heures, minutes et secondes (format "HH:MM:SS")
  const [inputHours, inputMinutes, inputSeconds] = time.split(":").map(Number);

  // Créer une nouvelle date avec les heures, minutes et secondes fournies (même jour)
  const inputTime = new Date(
    currentTime.getFullYear(),
    currentTime.getMonth(),
    currentTime.getDate(),
    inputHours,
    inputMinutes,
    inputSeconds
  );

  const diffInSeconds = (currentTime - inputTime) / 1000; // Différence en secondes
  const diffInMinutes = diffInSeconds / 60; // Convertir la différence en minutes

  if (diffInMinutes > 3) {
    // Retourner l'heure actuelle (HH:MM:SS)
    return currentTime.toTimeString().split(" ")[0];
  } else {
    // Retourner l'heure d'entrée (HH:MM:SS)
    return time;
  }
}

export const getDateDemainForAPI = () => {
  let maDate = new Date();

  // Ajouter un jour à la date actuelle pour obtenir la date de demain
  maDate.setDate(maDate.getDate() + 1);

  // Récupérer l'année
  let annee = maDate.getFullYear();

  // Récupérer le mois (attention : les mois commencent à 0 pour janvier)
  let mois = maDate.getMonth() + 1; // Ajoutez 1 car getMonth() retourne un index de 0 à 11

  // Récupérer le jour du mois
  let jour = maDate.getDate();

  // Ajouter un zéro devant les mois et les jours inférieurs à 10 pour un format correct (par ex. "2024-07-08")
  mois = mois < 10 ? "0" + mois : mois;
  jour = jour < 10 ? "0" + jour : jour;

  return annee + "-" + mois + "-" + jour;
};
export const getDAteChoisforAPI = (select, dateSele) => {
  //console.log( "testons la date " ,dateSele)

  if (select == 1) {
    return getDteforAPI();
  }
  if (select == 2) {
    return getDateDemainForAPI();
  }
  if (select == 3) {
    return dateSele;
  }
};

export function getWeekDates() {
  const today = new Date();

  const dayOfWeek = today.getDay();

  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  const diffToSaturday = dayOfWeek === 0 ? 0 : 6 - dayOfWeek;

  const monday = new Date(today);
  monday.setDate(today.getDate() + diffToMonday);

  const saturday = new Date(today);
  saturday.setDate(today.getDate() + diffToSaturday);

  const formatDate = (date) => date.toISOString().split("T")[0];

  return {
    monday: formatDate(monday),
    saturday: formatDate(saturday),
  };
}

export function groupByDate(data) {
  if (!data) return [];

  // Obtenez la date d'aujourd'hui sous forme d'objet Date
  const today = new Date().setHours(0, 0, 0, 0);

  const grouped = data.reduce((acc, item) => {
    const date = new Date(item.date).setHours(0, 0, 0, 0);

    // Vérifiez si la date de l'élément est à partir d'aujourd'hui
    if (date >= today) {
      let group = acc.find((g) => g.date === item.date);

      if (!group) {
        group = { date: item.date, items: [] };
        acc.push(group);
      }

      group.items.push(item);
    }

    return acc;
  }, []);

  // Trie les groupes par date
  grouped.sort((a, b) => new Date(a.date) - new Date(b.date));

  return grouped;
}
export function groupByDateAll(data) {
  if (!data) return [];

  // Obtenez la date d'aujourd'hui sous forme d'objet Date
  const today = new Date().setHours(0, 0, 0, 0);

  const grouped = data.reduce((acc, item) => {
    const date = new Date(item.date_absence).setHours(0, 0, 0, 0);

    // Vérifiez si la date de l'élément est à partir d'aujourd'hui

    let group = acc.find((g) => g.date_absence === item.date_absence);

    if (!group) {
      group = { date_absence: item.date_absence, items: [] };
      acc.push(group);
    }

    group.items.push(item);

    return acc;
  }, []);

  // Trie les groupes par date
  grouped.sort((a, b) => new Date(a.date_absence) - new Date(b.date_absence));

  return grouped;
}
export function getLastYear(arr) {
  if (arr.length === 0) {
    return null;
  }

  // Trier le tableau par ordre décroissant des noms
  arr.sort((a, b) => {
    const yearA = parseInt(a.nom.split("-")[0]);
    const yearB = parseInt(b.nom.split("-")[0]);
    return yearB - yearA;
  });

  // Retourner la première valeur après le tri
  return arr[0];
}

export function nombreAbs(array) {
  let abs = 0;
  for (let i = 0; i < array.length; i++) {
    abs = abs + array[0].items.length;
  }
  return abs;
}
export function supprimerDoublonsAbsences(absences) {
  const eleveIds = new Set(); // Set pour garder trace des élèves déjà vus
  return absences.filter((absence) => {
    if (eleveIds.has(absence.eleve.id)) {
      // Si l'élève a déjà été vu, on ignore cette absence
      return false;
    } else {
      // Si l'élève n'a pas encore été vu, on l'ajoute au Set et on garde cette absence
      eleveIds.add(absence.eleve.id);
      return true;
    }
  });
}

export function groupPointagetageByTime(courses) {
  // Tableau pour stocker les groupes
  const groupedCourses = [];

  // Parcourir la liste des cours
  courses.forEach((course) => {
    // Créer une clé unique basée sur l'heure de début et l'heure de fin
    const timeKey = `${course.heure_debut.slice(
      0,
      -3
    )} - ${course.heure_fin.slice(0, -3)}`;

    // Vérifier si un groupe avec ce timeKey existe déjà
    const existingGroup = groupedCourses.find(
      (group) => group.timeKey === timeKey
    );

    if (existingGroup) {
      // Si un groupe existe déjà pour ce timeKey, ajouter le cours à ce groupe
      existingGroup.cours.push(course);
    } else {
      // Sinon, créer un nouveau groupe avec ce timeKey et ajouter le cours
      groupedCourses.push({
        timeKey: timeKey,
        cours: [course],
      });
    }
  });

  return groupedCourses;
}
export function convertDate(inputDate) {
  // Créer un objet Date à partir de la chaîne d'entrée
  const date = new Date(inputDate);

  // Tableau des mois en français
  const mois = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  // Récupérer le jour, le mois (en texte), et l'année
  const jour = date.getDate().toString().padStart(2, "0");
  const moisTexte = mois[date.getMonth()];
  const annee = date.getFullYear();

  // Récupérer les heures et les minutes en UTC
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Construire la date formatée
  let dateString = `${jour} ${moisTexte} ${annee}`;

  // Ajouter l'heure si elle est différente de zéro
  if (hours !== 0 || minutes !== 0) {
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    dateString += ` à ${formattedHours}:${formattedMinutes}`;
  }

  return dateString;
}
export function formatPhoneNumber(number) {
  let str = number.toString();

  // Ajouter des espaces après chaque groupe de chiffres selon la longueur
  if (str.length === 9) {
    // Cas pour les numéros à 9 chiffres (ex : 770274292 -> 77 027 42 92)
    return str.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
  } else if (str.length === 8) {
    // Cas pour les numéros à 8 chiffres (ex : 71890123 -> 71 89 01 23)
    return str.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4");
  } else if (str.length === 10 && str.startsWith("0")) {
    // Cas pour les numéros à 10 chiffres commençant par 0 (ex : 0770274292 -> 07 702 74 92)
    return str.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
  } else {
    // Si la longueur n'est pas valide, retourner la chaîne d'origine
    return str;
  }
}
export function getInitials(fullName) {
  // Diviser le nom complet en mots en utilisant l'espace comme séparateur
  let nameParts = fullName.split(" ");

  // Prendre la première lettre de chaque mot et la mettre en majuscule
  let initials = nameParts.map((part) => part.charAt(0).toUpperCase());

  // Joindre les initiales avec un espace entre elles
  return initials.join(" ");
}
export function formatCurrencyCFA(amount) {
  return amount.toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
export function transformMoonth(num) {
  // Tableau de transformation selon les règles spécifiées
  const transformationMap = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  // Vérifier que le nombre est entre 1 et 12
  if (num < 1 || num > 12) {
    throw new Error("Le nombre doit être entre 1 et 12.");
  }

  // Retourner la valeur transformée
  return transformationMap[num - 1];
}

export function regrouperCoursEvaluations(data, dateString) {
  if (!data || (!data.evaluations && !data.cours)) return [];

  const fullData = [
    ...data.cours,
    ...recupererEvaluationsPourDate(data.evaluations, dateString),
  ];

  // Filtrer les données pour ne récupérer que celles correspondant à la date spécifiée (au format YYYY-MM-DD)
  // let dayData = fullData.filter(item => item.date === dateString);

  // Trier les données par heure de début
  let dayData = sortByStartTime(fullData);

  return dayData;
}
export function recupererEvaluationsPourDate(evaluations, dateCible) {
  // Filtrer les évaluations dont la date correspond à la date cible
  const evaluationsPourDate = evaluations.filter((e) => e.date == dateCible);

  return evaluationsPourDate;
}

export function sortByStartTime(arr) {
  arr.sort((a, b) => {
    const startTimeA = a.heure_debut.split(":");
    const startTimeB = b.heure_debut.split(":");

    const hourA = parseInt(startTimeA[0], 10);
    const minutesA = parseInt(startTimeA[1], 10);

    const hourB = parseInt(startTimeB[0], 10);
    const minutesB = parseInt(startTimeB[1], 10);

    if (hourA < hourB) {
      return -1;
    } else if (hourA > hourB) {
      return 1;
    } else {
      if (minutesA < minutesB) {
        return -1;
      } else if (minutesA > minutesB) {
        return 1;
      } else {
        return 0;
      }
    }
  });
  return arr;
}

export function renamePrenomToNomComplet(obj) {
  // Vérifie si le champ 'prenom' existe dans l'objet
  if (obj.name) {
    // Créer un nouvel objet avec 'nom_complet' à la place de 'prenom'
    let updatedObj = {
      ...obj, // Copier toutes les autres propriétés de l'objet
      nom_complet: obj.name, // Renommer 'prenom' en 'nom_complet'
    };
    // Supprimer 'prenom' de l'objet
    delete updatedObj.name;
    return updatedObj; // Retourner l'objet mis à jour
  }
  return obj; // Si 'prenom' n'existe pas, retourner l'objet sans modification
}

export function getMessageErreur(codeErreur) {
  switch (codeErreur) {
    // Codes 1xx - Information
    case 100:
      return "Continuer : Le serveur a reçu les en-têtes de la requête, et le client doit continuer à envoyer le corps.";
    case 101:
      return "Changement de protocole : Le serveur accepte de passer au protocole spécifié par le client.";
    case 102:
      return "Traitement en cours : La requête est en cours de traitement, mais aucune réponse n'est encore disponible.";

    // Codes 2xx - Succès
    case 200:
      return "Succès : La requête a été traitée avec succès.";
    case 201:
      return "Créé : La requête a été traitée avec succès, et une nouvelle ressource a été créée.";
    case 202:
      return "Accepté : La requête a été acceptée, mais le traitement est en cours.";
    case 204:
      return "Pas de contenu : La requête a été traitée avec succès, mais il n'y a aucun contenu à renvoyer.";

    // Codes 3xx - Redirections
    case 301:
      return "Déplacé de manière permanente : La ressource a été déplacée de façon permanente à une nouvelle URL.";
    case 302:
      return "Trouvé : La ressource est temporairement disponible à une autre URL.";
    case 304:
      return "Non modifié : La ressource n'a pas été modifiée depuis la dernière requête.";

    // Codes 4xx - Erreurs client
    case 400:
      return "Requête incorrecte : Veuillez vérifier les données envoyées.";
    case 401:
      return "Non autorisé : Veuillez vérifier vos identifiants.";
    case 403:
      return "Accès interdit : Vous n'avez pas les droits nécessaires.";
    case 404:
      return "Ressource non trouvée : L'URL demandée est introuvable.";
    case 405:
      return "Méthode non autorisée : La méthode utilisée pour cette requête est invalide.";
    case 408:
      return "Délai d'attente dépassé : Le serveur a mis trop de temps à répondre.";
    case 409:
      return "Conflit : Il y a un conflit dans la requête, par exemple, une ressource existante.";
    case 410:
      return "Ressource supprimée : La ressource demandée n'est plus disponible.";
    case 429:
      return "Trop de requêtes : Vous avez dépassé le nombre de requêtes autorisées. Veuillez réessayer plus tard.";

    // Codes 5xx - Erreurs serveur
    case 500:
      return "Erreur interne du serveur : Une erreur s'est produite sur le serveur.";
    case 501:
      return "Non implémenté : La fonctionnalité demandée n'est pas supportée par le serveur.";
    case 502:
      return "Passerelle incorrecte : Le serveur a reçu une réponse invalide en tant que passerelle.";
    case 503:
      return "Service indisponible : Le serveur est temporairement surchargé ou en maintenance.";
    case 504:
      return "Délai d'attente dépassé : Le serveur n'a pas répondu à temps.";
    case 505:
      return "Version HTTP non supportée : La version HTTP utilisée n'est pas supportée par le serveur.";

    // Code inconnu
    default:
      return "Une erreur inconnue est survenue.";
  }
}

export function minMaxAvg(arr) {
  if (arr.length === 0) {
    return { min: null, max: null, avg: null }; // Gérer le cas d'un tableau vide
  }

  const min = Math.min(...arr);
  const max = Math.max(...arr);
  const avg = Number((arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2));
  return { min, max, avg };
}

export const createChartData = (notes) => {
  const intervals = [
    { min: 0, max: 8, name: "0-8" },
    { min: 8, max: 14, name: "8-14" },
    { min: 14, max: 20, name: "14-20" },
  ];

  const data = intervals.map((interval) => ({
    name: interval.name,
    value: notes.filter((note) => note >= interval.min && note < interval.max)
      .length,
  }));

  return data;
};
  export function verifierValidite (nom_eleve, nom_tuteur, numero_telephone,nationnalite,lieuxDeNaissance,lienDeParente,addresTuteur,setMessageError)  {
  // Vérifier que les noms ne sont pas vides
  if (!nom_eleve||
    !nom_tuteur||
    !nationnalite||
    !lieuxDeNaissance||
    !lienDeParente||
    !addresTuteur
  ) {
    setMessageError("tous les champs sont obligatoires")
    return  false;
  }



  // Vérifier que le numéro de téléphone est valide
  const motif = /^(77|78|76|75|70)\d{7}$/;

  if (motif.test(numero_telephone)) {

    return true;
  } else {
    setMessageError("Numéro de téléphone invalide")
    return  false;
  }
};
